import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { errorAlert } from '@/helpers/alerts';

const httpClient = axios.create({
  baseURL: 'https://api.alem-erp.kz/api/v1',
});

httpClient.interceptors.request.use((config) => {
  const token = store.getters.token;
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : null,
  };
  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.commit('logout');
    } else if (error.response && error.response.status === 404) {
      router.push({
        name: 'not-found',
      });
    } else {
      errorAlert();
    }
    return Promise.reject(error);
  },
);

export default httpClient;
