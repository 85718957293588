import * as bootstrap from 'bootstrap';

const showModal = (modalEl) => {
  if (!modalEl) {
    return;
  }

  const myModal = new bootstrap.Modal(modalEl);
  myModal.show();
};

const hideModal = (modalEl) => {
  if (!modalEl) {
    return;
  }

  const myModal = bootstrap.Modal.getInstance(modalEl);
  myModal.hide();
};

const disableModal = (modalEl) => {
  if (!modalEl) {
    return;
  }

  const myModal = bootstrap.Modal.getInstance(modalEl);
  myModal._config.backdrop = 'static';
};

const enableModal = (modalEl) => {
  if (!modalEl) {
    return;
  }

  const myModal = bootstrap.Modal.getInstance(modalEl);
  myModal._config.backdrop = 'true';
};

const removeModalBackdrop = () => {
  if (document.querySelectorAll('.modal-backdrop.fade.show').length) {
    document.querySelectorAll('.modal-backdrop.fade.show').forEach((item) => {
      item.remove();
    });
  }
};

const showLoadingModal = () => {
  const loadingModalHtml = document.getElementById('loading-modal');
  const myModal = bootstrap.Modal.getOrCreateInstance(loadingModalHtml);

  myModal.show();
};

const hideLoadingModal = () => {
  const loadingModalHtml = document.getElementById('loading-modal');
  const myModal = bootstrap.Modal.getOrCreateInstance(loadingModalHtml);

  myModal.hide();
};

const hideTooltip = () => {
  const tooltipsArray = [...document.getElementsByClassName('tooltip')];

  tooltipsArray.forEach(function (tooltip) {
    tooltip.remove();
  });
};

const registerDropdown = () => {
  const dropdownElementList = document.querySelectorAll('.btn-filter');
  [...dropdownElementList].map(
    (dropdownToggleEl) =>
      new bootstrap.Dropdown(dropdownToggleEl, {
        popperConfig: {
          strategy: 'fixed',
        },
      }),
  );
};

const hideDropdown = () => {
  const dropdownElementList = document.querySelectorAll("[aria-expanded='true']");
  dropdownElementList.forEach(function (dropdownToggle) {
    var clickEvent = new Event('mousedown', { bubbles: true, cancelable: true });
    dropdownToggle.dispatchEvent(clickEvent);
  });
};

(() => {
  new bootstrap.Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
  });
})();

export {
  bootstrap,
  showModal,
  hideModal,
  disableModal,
  enableModal,
  removeModalBackdrop,
  hideTooltip,
  showLoadingModal,
  hideLoadingModal,
  registerDropdown,
  hideDropdown,
};
