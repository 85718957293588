import router from '@/router';

import { successSaveAlert, successLoginAlert, confirmAlert } from '@/helpers/alerts';
import { login, editUser } from '@/api/login';

export default {
  state() {
    return {
      token: localStorage.getItem('userToken'),
      user: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null,
    };
  },
  mutations: {
    saveToken(state, token) {
      localStorage.setItem('userToken', token);
      state.token = token;
    },
    removeToken(state) {
      localStorage.removeItem('userToken');
      state.token = null;
    },
    saveUser(state, user) {
      localStorage.setItem('userData', JSON.stringify(user));
      state.user = user;
    },
    removeUser(state) {
      localStorage.removeItem('userData');
      state.user = null;
    },
    logout() {
      this.commit('removeToken');
      this.commit('removeUser');
      this.commit('restorePermissions');

      router.push({
        name: 'login-page',
      });
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
    isUserAuthenticated(state) {
      return !!state.token;
    },
    isAdmin(state) {
      return state.user?.is_admin;
    },
    user(state) {
      return state.user;
    },
    userId(state) {
      return state.user?.id;
    },
  },
  actions: {
    async login(context, data) {
      const { data: response } = await login(data);
      context.commit('saveToken', response.token);
      context.commit('saveUser', response.user);

      router
        .push({
          name: 'dashboard',
        })
        .then(() => successLoginAlert('Вы успешно авторизованы!'));
    },
    logout(context) {
      confirmAlert('Подтвердить действие').then((result) => {
        if (result.isConfirmed) {
          context.commit('logout');
        }
      });
    },
    async profileUpdate(context, data) {
      const { data: response } = await editUser(data);
      context.commit('saveUser', response);
      successSaveAlert();
    },
  },
};
