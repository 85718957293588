export default {
  state() {
    return {
      isNavbarMinimized: localStorage.getItem('isNavbarMinimized') == 'true' ? true : false,
    };
  },
  mutations: {
    toggleNavbar(state) {
      localStorage.setItem('isNavbarMinimized', !state.isNavbarMinimized);
      state.isNavbarMinimized = !state.isNavbarMinimized;
    },
    showNavbar(state) {
      localStorage.setItem('isNavbarMinimized', false);
      state.isNavbarMinimized = false;
    },
  },
  getters: {
    isNavbarMinimized(state) {
      return state.isNavbarMinimized;
    },
  },
};
