import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import InlineSvg from 'vue-inline-svg';
import { bootstrap } from '@/helpers/bootstrap';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue';
import { setupCalendar } from 'v-calendar';
import maska from 'maska';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import '@/assets/scss/style.scss';

const app = createApp(App);
app.component('avesomebar', OverlayScrollbarsComponent);
app.component('v-select', vSelect);
app.component('inline-svg', InlineSvg);
app.component('VueDatePicker', VueDatePicker);
app.use(store).use(router).use(bootstrap).use(maska).use(setupCalendar, {}).mount('#app');
