import Swal from 'sweetalert2';

const successAlert = (message) => {
  return Swal.fire({
    html: `
        <div class="main-alert">
            <span class="main-alert__title">${message}</span>
            <img class="main-alert__circule success" src="${require('@/assets/images/circule.svg')}" alt="circule" />
        </div>
    `,
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
  });
};

const successSaveAlert = () => {
  return Swal.fire({
    html: `
        <div class="main-alert">
            <span class="main-alert__title">Успешно сохранено</span>
            <img class="main-alert__circule success" src="${require('@/assets/images/circule.svg')}" alt="circule" />
        </div>
    `,
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
  });
};

const successUpdateAlert = () => {
  return Swal.fire({
    html: `
        <div class="main-alert">
            <span class="main-alert__title">Успешно обновлено</span>
            <img class="main-alert__circule success" src="${require('@/assets/images/circule.svg')}" alt="circule" />
        </div>
    `,
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
  });
};

const successDeleteAlert = () => {
  return Swal.fire({
    html: `
        <div class="main-alert">
            <span class="main-alert__title">Успешно удалено</span>
            <img class="main-alert__circule success" src="${require('@/assets/images/circule.svg')}" alt="circule" />
        </div>
    `,
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
  });
};

const errorAlert = (message = 'Ошибка загрузки, попробуйте еще раз!') => {
  setTimeout(() => {
    return Swal.fire({
      html: `
            <div class="main-alert">
                <span class="main-alert__title">${message}</span>
                <img class="main-alert__circule danger" src="${require('@/assets/images/circule.svg')}" alt="circule" />
            </div>
        `,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
  }, 0);
};

const confirmAlert = (message) => {
  return Swal.fire({
    html: `
      <span class="line-separator line-separator--light mb-5"></span>
      ${message}
    `,
    width: 700,
    showDenyButton: true,
    showCloseButton: true,
    confirmButtonText: 'Да',
    denyButtonText: 'Нет',
    customClass: {
      htmlContainer: 'mt-5 mx-0',
      actions: 'border-line-separator w-100 pb-5 mb-4',
      confirmButton: 'swal-delete-confirm-btn',
      denyButton: 'swal-deny-btn',
    },
  });
};

const successLoginAlert = () => {
  return Swal.fire({
    position: 'bottom-end',
    html: `
        <div class="login-alert">
            <span class="login-alert__title">Вы успешно авторизованы!</span>
        </div>
    `,
    width: 370,
    backdrop: false,
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    willOpen: () => {
      localStorage.setItem('loginModal', true);
    },
    didClose: () => {
      localStorage.removeItem('loginModal');
    },
  });
};

const newNotificationAlert = () => {
  return Swal.fire({
    position: 'top-end',
    html: `
        <div class="login-alert">
            <span class="login-alert__title">Новое уведомление!</span>
        </div>
    `,
    width: 370,
    backdrop: false,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
};

export {
  successAlert,
  successSaveAlert,
  successUpdateAlert,
  successDeleteAlert,
  errorAlert,
  confirmAlert,
  successLoginAlert,
  newNotificationAlert,
};
