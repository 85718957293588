import isAuth from '@/router/middleware/isAuth';
import isNotAuth from '@/router/middleware/isNotAuth';
import isAdmin from '@/router/middleware/isAdmin';
import isNotAdmin from '@/router/middleware/isNotAdmin';

const routes = [
  {
    path: '/',
    component: () => import('@/layout/Layout.vue'),
    meta: { middleware: [isAuth] },
    redirect: { name: 'dashboard' },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: { isAdmin: true },
        component: () => import('@/views/DashboardPage.vue'),
        // component: () => import('@/views/ProductionWorkerDashboard.vue'),
      },
      {
        path: '/applications',
        name: 'applications-page',
        meta: { headerTitle: 'Заказы' },
        component: () => import('@/views/ApplicationsPage.vue'),
      },
      {
        path: '/applications/:id(\\d+)',
        name: 'formation-page',
        meta: { headerTitle: 'Заказы' },
        component: () => import('@/views/FormationPage.vue'),
      },
      {
        path: '/applications/:id(\\d+)/plan',
        name: 'application-plan-page',
        meta: { headerTitle: 'Заказы' },
        component: () => import('@/views/ApplicationPlanPage.vue'),
      },
      {
        path: '/plan',
        name: 'plan-page',
        component: () => import('@/views/PlanPage.vue'),
        meta: { headerTitle: 'План/прогресс' },
      },
      {
        path: '/plan/:id(\\d+)',
        name: 'plan-task-page',
        component: () => import('@/views/PlanTaskPage.vue'),
        meta: { headerTitle: 'План/прогресс' },
      },
      {
        path: '/plan/:dealid(\\d+)/group/:id(\\d+)',
        name: 'subtask-group-page',
        component: () => import('@/views/SubtaskGroupPage.vue'),
        meta: { isReactiveHeaderTitle: true },
      },
      {
        path: '/plan/:dealid(\\d+)/group/:taskid(\\d+)/subtasks/:id(\\d+)',
        name: 'subtasks-page',
        component: () => import('@/views/SubtasksPage.vue'),
        meta: { isReactiveHeaderTitle: true },
      },
      {
        path: '/depot',
        name: 'depot-page',
        meta: { headerTitle: 'Склад' },
        component: () => import('@/views/DepotPage.vue'),
      },
      {
        path: '/materials/:id',
        name: 'materials-page',
        meta: { headerTitle: 'О складе' },
        component: () => import('@/views/MaterialsPage.vue'),
      },
      {
        path: '/materials/:materialId/reserve/:reserveId',
        name: 'reserve-products-page',
        meta: { headerTitle: 'Резерв' },
        component: () => import('@/views/ReserveProductsPage.vue'),
      },
      {
        path: '/depot/:id(\\d+)',
        name: 'fees-page',
        component: () => import('@/views/FeesPage.vue'),
      },
      {
        path: '/accounting',
        name: 'accounting-page',
        meta: { headerTitle: 'Бухгалтерский учёт' },
        component: () => import('@/views/AccountingPage.vue'),
      },
      {
        path: '/analytics',
        name: 'analytics-page',
        meta: { headerTitle: 'Аналитика' },
        component: () => import('@/views/AnalyticsPage.vue'),
      },
      {
        path: '/profile',
        name: 'profile-page',
        component: () => import('@/views/ProfilePage.vue'),
        meta: { headerTitle: 'Профиль', middleware: [isAdmin] },
        children: [
          {
            path: '/profile/staff',
            name: 'staff-page',
            meta: {
              topbarBtn: { id: '#add-staff-modal', title: 'Добавить сотрудника' },
              middleware: [isNotAdmin],
            },
            components: {
              default: () => import('@/views/StaffPage.vue'),
              topbar: () => import('@/components/profile/ProfileTopbar.vue'),
            },
          },
          {
            path: '/profile/staff-roles',
            name: 'staff-roles-page',
            meta: {
              topbarBtn: { id: '#add-staff-role-modal', title: 'Добавить роль' },
              middleware: [isNotAdmin],
            },
            components: {
              default: () => import('@/views/StaffRolesPage.vue'),
              topbar: () => import('@/components/profile/ProfileTopbar.vue'),
            },
          },
        ],
      },
    ],
  },

  {
    path: '/sign-in',
    name: 'login-page',
    component: () => import('@/views/Login.vue'),
    meta: { middleware: [isNotAuth] },
  },
  {
    path: '/404',
    name: 'not-found',
    component: () => import('@/views/404.vue'),
    meta: { middleware: [isAuth] },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

export default routes;
