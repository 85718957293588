import axios from '@/api/axios';

const getPermissions = async () => {
  const { data: response } = await axios.get('/permissions');
  return response.data;
};

const getUserPermissions = async () => {
  const { data: response } = await axios.get('/users/permissions');
  return response.data;
};

export { getPermissions, getUserPermissions };
