import axios from '@/api/axios';
import { errorAlert } from '@/helpers/alerts';

const login = async (data) => {
  const response = await axios.post('/login', data);
  return response.data;
};

const editUser = async (data) => {
  try {
    const response = await axios.put('/users/' + data.id, {
      ...data,
    });
    return response.data;
  } catch (error) {
    errorAlert('Ошибка сервера!');
    throw error;
  }
};

export { login, editUser };
