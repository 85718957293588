import { getUserPermissions } from '@/api/permission';
import { errorAlert } from '@/helpers/alerts';

export default {
  state() {
    return {
      permissions: {},
      isLoaded: false,
    };
  },
  mutations: {
    savePermissions(state, permissions) {
      state.permissions = permissions.reduce((acc, item) => {
        acc[item.module_id] = {
          permissions: item.permissions,
        };
        return acc;
      }, {});
      state.isLoaded = true;
    },
    restorePermissions(state) {
      state.permissions = {};
      state.isLoaded = false;
    },
  },
  getters: {
    isPermissionsLoaded(state) {
      return state.isLoaded;
    },
    getUserPermissions(state) {
      return state.permissions;
    },
  },
  actions: {
    async getUserPermissions(context) {
      try {
        context.commit('restorePermissions');
        const data = await getUserPermissions();
        context.commit('savePermissions', data);
      } catch {
        errorAlert();
      }
    },
  },
};
